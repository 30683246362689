import { get } from "svelte/store";
import { page } from "$app/stores";


export type SupabaseEntity = {
	Row: any;
	Insert: any;
	Update: any;
};

/**
 * Defines a generic layout for all Supabase api classes.
 */
export interface ISupabaseApi<T extends SupabaseEntity> {
	readonly TABLE_NAME: string;
	create: (dto: T['Insert']) => void;
	update: (dto: T['Update'], identifierColumnName: string, identifierColumnValue: string) => void;
	// TODO: Add all crud methods.
}

/**
 * Defines a generic layout for all Supabase api classes.
 */
export abstract class AbstractSupabaseApi<T extends SupabaseEntity> implements ISupabaseApi<T> {
	constructor(readonly TABLE_NAME: string) { };

	/**  Fetches the entity by given id. */
	async getById(id: string) {
		const { error: createPostError, data } = await get(page).data.supabase.from(this.TABLE_NAME).select("*").eq("id", id).single();
		if (createPostError) {
			throw createPostError.message;
		}

		return data;
	};

	async create(dto: T['Insert']) {
		const { error: createPostError } = await get(page).data.supabase.from(this.TABLE_NAME).insert(dto);
		if (createPostError) {
			throw createPostError;
		}
	};

	/**
	 * Call this method to update values of a database entry identifying it via a certain unique column value.
	 * @param dto The object with the names of fields that should be updated and their respective values.
	 * Example: {
					username: "newUsername",
					otherFieldName: "otherFieldValue"
				}
	 * @param identifierColumnName The name of the column used as the unique identifier.
	 * @param identifierColumnValue The value of the column used as the unique identifier.
	 */
	async update(dto: T['Update'], identifierColumnName: string, identifierColumnValue: string) {
		// const currentProfile = get(profile);
		const { error: updatePostError } = await get(page).data.supabase.from(this.TABLE_NAME).update(dto).eq(identifierColumnName, identifierColumnValue);
		if (updatePostError) {
			throw updatePostError;
		}
	};
	// TODO: Add all crud methods.
}